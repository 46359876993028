<template>
  <smart-dialog v-model="localShow" width="800px" :max-width="1000" title="Envoi des notifications"
                @close="close()">
    <v-tabs v-model="tabToMail">
      <v-tab v-for="(receiver,i) in Object.keys(contents)" :key="i"> {{ receiver }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabToMail">
      <v-tab-item v-for="(receiver,i) in Object.keys(contents)" :key="i+'_item'">
        <div class="pa-3">
          <v-checkbox
              v-model="mailToReceivers[i]"
              label="Envoyé un mail à ce fournisseur"
              hide-details
              class="mb-3"
          ></v-checkbox>
          <mail-composer ref="mailSender"
                         v-if="mailToReceivers[i]"
                         :from="from"
                         :subject="contents[receiver].subject ?? ''"
                         :tos="contents[receiver]?.suppliers.length > 0 ? (contents[receiver].suppliers[0]?.emails ?? []) : []"
                         :loading="false"
                         @send="(obj) => send(obj)"
                         hide-send-btn
                         :message="contents[receiver].message"/>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <template #actions="">
      <smart-btn @click.native="sendMails">valider</smart-btn>
    </template>
  </smart-dialog>
</template>
<script>

import {defineComponent} from "vue";
import MailComposer from "@/components/commons/MailComposer.vue";
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default defineComponent({
  components: {MailComposer},
  props: {
    show: {type: Boolean, default: false},
    contents: {type: Object, default: null},
    from: { type: String, default: null}
  },
  data() {
    return {
      localShow: false,
      tabToMail: null,
      mails: [],
      mailToReceivers: [],
    }
  },
  computed: {},
  methods: {
    sendMails() {
      if (this.$refs.mailSender.length > 0) {
        this.$refs.mailSender.map(composer => composer.send())
      } else this.close();
    },
    close() {
      this.localShow = false
      this.tabToMail = null
      this.mails = []
      this.mailToReceivers = []
      this.$emit("close")
    },
    send(email) {
      const url = `/api/mail/send`;
      post(url, email)
          .json((r) => {
            if (r) {
              EventBus.$emit("toaster-msg", "Emails envoyé•s");
              this.close()
            } else {
              EventBus.$emit("toaster-error", "Echec de l'envoi");
              this.close()
            }
          })
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        Object.keys(this.contents).map(receiver => {
          this.mailToReceivers.push(this.contents[receiver].send)

        })
        this.localShow = newVal
      },
    },
  },
})
</script>