<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="nav">
      <v-tabs>
        <v-tab @click="setCurrentWidget(widgets.WidgetActivitySiteDesti)">Activité</v-tab>
        <v-tab @click="setCurrentWidget(widgets.WidgetActivitySiteDestiN1)">Activité N-1</v-tab>
      </v-tabs>
    </template>
    <template slot="drawer">
      <v-form ref="searchform" v-if="searchform" @submit.prevent="loadData">
        <v-row class="mt-1 ml-1">
          <v-col cols="12" class="my-0 ml-3 pa-0">
            <v-subheader light class="pa-0 mt-1 mb-0 mx-0 form-subheader"
            >Réservation
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>

          <v-col class="pr-1 mt-0 pt-0" cols="6">
            <smart-picker
                v-model="searchform.bookingDateStart"
                :stopDate="searchform.bookingDateStop"
                :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col class="pr-1 mt-0 pt-0" cols="6">
            <smart-picker
                v-model="searchform.bookingDateStop"
                :startDate="searchform.bookingDateStart"
                :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>

          <v-col cols="12" class="mt-2 mb-0 ml-3 pa-0">
            <v-subheader light class="pa-0 mt-2 mb-0 mx-0 form-subheader"
            >Dates de voyage
            </v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col class="pr-1 mt-0 pt-0" cols="6">
            <smart-picker
                v-model="searchform.tripDateStart"
                :stopDate="searchform.tripDateStop"
                :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col class="pr-1 mt-0 pt-0" cols="6">
            <smart-picker
                v-model="searchform.tripDateStop"
                :startDate="searchform.tripDateStart"
                :inputOpts="{ label: $t('end') }"
            ></smart-picker>
          </v-col>
          <v-col cols="8" class="mb-0 pb-0">
            <v-subheader
                light
                class="pa-0 mb-0 mx-0 mt-4 form-subheader solo-subheader"
            >Etiquettes
            </v-subheader
            >
          </v-col>

          <v-col cols="12" class="mt-0 pt-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <smart-multi-select
                v-model="searchform.tags"
                :items="tags"
                :all-is-same-as-empty="false"
                :default-select-all="false"
                item-value="id"
                item-text="name"
            ></smart-multi-select>
          </v-col>
        </v-row>

        <smart-btn type="submit" :loading="loading" block primary class="mt-5"
        >Rechercher
        </smart-btn
        >
        <smart-btn
            block
            tertiary
            @click.native="
            clearSearchform();
            loadData();
          "
        >Réinitialiser la recherche
        </smart-btn
        >
      </v-form>
    </template>
    <template slot="content">
      <v-row justify="space-around">
        <v-col cols="12" class="mb-3 mt-3">
          <component
              :is="currentWidget"
              @loading="setLoading"
              :bus="widgetBus"
          ></component>
        </v-col>
      </v-row>
    </template>
  </layout>
</template>

<script>
import Vue from "vue";
import Layout from "../Layout";

import WidgetActivitySiteDesti from "../../components/commons/widgets/WidgetActivitySiteDesti";
import WidgetActivitySiteDestiN1 from "../../components/commons/widgets/WidgetActivitySiteDestiN1.vue";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";
import {get} from "../../utils/api";

export default {
  name: "ActivityStats",
  components: {
    WidgetActivitySiteDesti,
    WidgetActivitySiteDestiN1,
    Layout,
    SmartMultiSelect,
  },
  data() {
    return {
      //------
      widgetBus: new Vue(),
      //searchform
      defaultSearchform: {
        selectedSites: [],
        selectedNetworks: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        client: null,
        ref: null,
        bookingDateStart: this._nowFormatted("dd/MM/yyyy"),
        bookingDateStop: this._nowFormatted("dd/MM/yyyy"),
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: ["booked", "cancelled"],
        supplierStatus: [],
        paymentStatus: null,
        excludeTags: false,
        tags: [],
      },
      tags: [],
      searchform: {
        tags: [],
      },
      loading: false,
      currentWidget: WidgetActivitySiteDesti,
      widgets: {
        WidgetActivitySiteDesti: WidgetActivitySiteDesti,
        WidgetActivitySiteDestiN1: WidgetActivitySiteDestiN1,
      }
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {},
  methods: {
    init() {
      setTimeout(() => {
        get("/api/search/tags").json((tags) => {
          this.tags = [...tags];
        });
        this.clearSearchform();
        this.loadData();
      }, 1);
    },

    loadData() {
      if (!this.loading) {
        this.widgetBus.$emit("search", this.searchform);
      }
    },
    clearSearchform() {
      this.searchform = {...this.defaultSearchform};
    },
    setLoading(loading) {
      this.loading = loading;
    },
    setCurrentWidget(widget) {
      this.currentWidget = widget;
      setTimeout(() => {
        this.loadData()
      }, 1)
    }
  },
};
</script>

<style scoped>
.v-subheader {
  width: 100%;
  height: 20px;
  color: #616161;
}
</style>
