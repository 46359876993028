<template>
  <v-form v-on:submit.prevent="send" ref="form" v-model="valid">
    <v-card-text>
      <v-row dense>
        <v-col cols="11">
          <v-combobox
              v-model="email.from"
              chips
              label="De"
              hide-details
              append-icon
              :rules="[...rules.nonEmpty(), ...rules.email()]"
          ></v-combobox>
        </v-col>

        <v-col cols="11">
          <v-combobox
              v-model="email.to"
              chips
              deletable-chips
              label="À"
              multiple
              hide-details
              append-icon
              :rules="[...rules.nonEmptyArray(), ...rules.emailArray()]"
          ></v-combobox>
        </v-col>
        <v-col cols="1" class="grey--text text-right align-self-end pr-2">
          <span class="addCc" v-show="!withCc" @click="withCc=true">Cc</span>
          <span class="addCc" v-show="!withCci" @click="withCci=true">Cci</span>
        </v-col>

        <v-col cols="12" v-show="withCc">
          <v-row dense>
            <v-col cols="11">
              <v-combobox
                  v-model="email.cc"
                  chips
                  deletable-chips
                  label="Cc"
                  hide-details
                  multiple
                  append-icon
                  :rules="rules.emailArray()"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" v-show="withCci">
          <v-row dense>
            <v-col cols="11">
              <v-combobox
                  v-model="email.cci"
                  chips
                  deletable-chips
                  label="Cci"
                  hide-details
                  multiple
                  append-icon
                  :rules="rules.emailArray()"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-col>

        <v-col
            class="mb-2 pa-1"
            :class="{'col-6':showInternalAttachment && withInternalAttachment, 'col-10':!withInternalAttachment}"
        >
          <v-text-field
              label="Objet"
              v-model="email.subject"
              :rules="rules.nonEmpty()"
              hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="pa-1" v-show="showInternalAttachment && withInternalAttachment">
          <v-select
              ref="attachmentsselect"
              :items="internalAttachments"
              v-model="email.internalAttachments"
              label="Pièce jointe "
              multiple
              chips
              small-chips
              item-value="value"
              item-text="label"
              hide-details
              :menu-props="{closeOnClick:false, closeOnContentClick:true, openOnClick:false}"
          ></v-select>
        </v-col>
        <v-spacer/>
        <v-col
            cols="2"
            class="grey--text text-right align-self-end pa-1 mb-2"
            v-show="showInternalAttachment || showExternalAttachment"
        >
          <span class="addCc" @click="withInternalAttachment=true">
            <v-icon small>mdi-paperclip</v-icon>Documents
          </span>
        </v-col>
        <v-col cols="12" v-show="withInternalAttachment">
          <v-chip
              v-for="(attachment,attachmentIndex)  in email.externalAttachments"
              :key="attachmentIndex"
              small
              close
              outlined
              @input="removeExternalAttachment(attachment)"
          >{{ attachment.filename }}
          </v-chip>
        </v-col>
        <v-col cols="12" v-show="withInternalAttachment">
          <document-uploader
              url="/api/file/upload/attachment"
              :auto="true"
              @uploaded="handleExternalAttachments"
          />
        </v-col>
        <v-col cols="12" v-show="withInternalAttachment">
          <v-divider class="mb-1"></v-divider>
        </v-col>
        <v-col cols="12">
          <smart-editor v-model="email.message"></smart-editor>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="actions-btn" v-show="!hideSendBtn">
      <span class="red--text" v-if="forceDisabled? forceDisabled : !valid ">{{ messageError }}</span>
      <v-spacer></v-spacer>
      <smart-btn primary type="submit" :disabled="forceDisabled? forceDisabled : !valid " :loading="isLoading">
        <v-icon small class="mr-1">send</v-icon>
        {{ $t('send') }}
      </smart-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import FormMixin from "../mixins/FormMixin";
import {get} from "@/utils/api";
import DocumentUploader from "./DocumentUploader";
import SmartEditor from "./SmartEditor";
import _ from "lodash";

export default {
  name: "MailComposer",
  mixins: [FormMixin],
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    from: {type: String, default: null},
    tos: {
      type: Array,
      required: false,
    },
    subject: {
      type: String,
      required: false,
    },
    showInternalAttachment: {
      type: Boolean,
      required: false,
      default: false,
    },
    showExternalAttachment: {
      type: Boolean,
      required: false,
      default: true,
    },
    defaultInternalAttachment: {
      type: Array,
      required: false,
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    message: {
      type: String,
      required: false,
    },
    forceDisabled: {type: Boolean, default: false},
    messageError: {type: String, default: null},
    hideSendBtn: {type: Boolean, default: false},
  },
  components: {
    SmartEditor,
    DocumentUploader,
  },
  data() {
    return {
      valid: true,
      isLoading: false,

      inputs: {
        to: true,
        object: true,
      },

      withCc: false,
      withCci: false,
      defaultEmail: {
        from: "contact@hotelissima.com",
        to: [],
        cc: [],
        cci: [],
        subject: null,
        message: "",
        internalAttachments: [],
        externalAttachments: [],
      },
      email: null,

      withInternalAttachment: false,
      internalAttachments: [
        {label: "Devis", value: "quotation"},
        {label: "Tous les Vouchers", value: "vouchers"},
        {label: "Assistance voyage", value: "assistance"},
        {label: "Contrat de vente", value: "bulletin"},
        {label: "Dernière facture", value: "lastinvoice"},
      ],
    };
  },
  created() {
    this.reset();
  },
  watch: {
    loading(newVal) {
      this.isLoading = newVal;
    },
    defaultInternalAttachment(newVal) {
      this.reset();
    },
  },
  computed: {},
  methods: {

    send() {
      if (this.valid && this.$refs.form.validate()) {
        this.$emit("send", this.email);
        this.reset();
      }
    },
    reset() {
      this.initFrom();
      this.email = _.cloneDeep(this.defaultEmail);

      this.email.from = this.from ?? this.defaultEmail.from;
      this.email.to = this.tos && this.tos.length > 0 ? this.tos : [];
      this.email.subject = this.subject;
      this.email.internalAttachments = this.defaultInternalAttachment || [];
      this.email.subject = this.subject ? this.subject : this.email.subject;
      this.email.message = this.message ? this.message : this.email.message;
      this.email.externalAttachments =
          this.defaultEmail.externalAttachments || [];
      this.withInternalAttachment = this.defaultInternalAttachment != null;

      if (this.$refs && this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    initFrom() {

      if (this.siteId != null && this.from == null) {
        const url = `/api/mail/sender/${this.siteId}`;
        get(url).text((r) => (this.email.from = r));
      }
    },
    handleExternalAttachments(files) {
      const filePaths = files.flatMap((f) => JSON.parse(f.xhr.response));
      this.email.externalAttachments = _.uniqBy(
          [...this.email.externalAttachments, ...filePaths],
          "path"
      );

    },
    removeExternalAttachment(attachment) {
      this.email.externalAttachments = this.email.externalAttachments.filter(
          (item) => item.path != attachment.path
      );
    },
  },
};
</script>

<style scoped>
.addCc {
  cursor: pointer;
}
</style>
