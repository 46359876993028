<template>
  <layout :drawer="false">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'supplier-invoice-list' }"
        >Liste des Factures
        </v-tab
        >
      </v-tabs>
    </template>
    <template slot="drawer"></template>

    <template slot="content">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-card v-if="supplierInvoice">
                <v-card-title>
                  <span v-if="supplierInvoice.id === -1">Nouvelle Facture</span>
                  <span v-else
                  >{{
                      supplierInvoice.invoiceType == "credit_note"
                          ? "Avoir"
                          : "Facture"
                    }}
                    n°{{ supplierInvoice.id }}
                    {{
                      supplierInvoice.parentId
                          ? "sur la facture n°" + supplierInvoice.parentId
                          : ""
                    }}</span
                  >

                  <v-chip label color="blue" disabled small outlined v-if="supplierInvoice != null">{{
                      $t("supplierinvoicestatus." + supplierInvoice.status)
                    }}
                  </v-chip>
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="3">
                      <v-autocomplete
                          v-model="supplierInvoice.supplierAccountingId"
                          :disabled="!editable"
                          :items="listSuppliers"
                          item-value="id"
                          item-text="name"
                          label="Fournisseur"
                          :rules="rules.abs('Fournisseur obligatoire')"
                          @change="setDefaultBankAccount()"
                          required
                          validate-on-blur
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                          v-model="supplierInvoice.ref"
                          :disabled="!editable"
                          :rules="rules.nonEmpty()"
                          validate-on-blur
                          label="Référence"
                          required
                      />
                    </v-col>

                    <v-col cols="1">
                      <v-select
                          v-model="supplierInvoice.bankAccount"
                          :disabled="!editable"
                          :items="bankAccounts"
                          :rules="rules.nonEmpty()"
                          validate-on-blur
                          label="Compte banque"
                          required
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1">
                      <v-text-field
                          v-model="supplierInvoice.amount"
                          :disabled="!editable"
                          required
                          label="Montant"
                          :rules="rules.number()"
                          :hint="euroAmount"
                          persistent-hint
                          reverse
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-select
                          v-model="supplierInvoice.currencyCode"
                          :disabled="!editable"
                          required
                          label="Monnaie"
                          :items="listCurrency"
                          :rules="rules.nonEmpty()"
                          item-value="code"
                          item-text="symbol"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                          v-if="supplierInvoice.currencyCode !== 'EUR'"
                          :disabled="!editable"
                          label="Portefeuille"
                          v-model="supplierInvoice.walletId"
                          :items="wallet4currency"
                          item-value="id"
                          item-text="name"
                          @change="changeWallet"
                      >
                        <template slot="selection" slot-scope="{ item, index }">
                          <span v-if="item.id === null">{{ item.name }}</span>
                          <span v-else>{{ item.name }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                          v-if="
                          supplierInvoice.currencyCode !== 'EUR' &&
                          showCustomEuroRate
                        "
                          :disabled="!disableChangeRate"
                          reverse
                          :prefix="$t(supplierInvoice.currencyCode + '-symbol')"
                          suffix="@"
                          v-model="supplierInvoice.euroRate"
                          right
                          :label="'Taux: 1€ = ?'"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="2">
                      <smart-picker
                          :disabled="!editable"
                          v-model="supplierInvoice.creationDate"
                          label="Date de la facture"
                          format="dd/MM/yyyy HH:mm:ss"
                          class="pt-0"
                      ></smart-picker>
                    </v-col>
                    <v-col cols="2">
                      <smart-picker
                          v-model="supplierInvoice.maxPaymentDate"
                          format="dd/MM/yyyy HH:mm:ss"
                          label="Date limite de paiement"
                      ></smart-picker>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" v-if=" supplierInvoice != null && supplierInvoice.status != 'waiting'">
                      <v-text-field
                          slot="activator"
                          label="Date de validation"
                          v-model="supplierInvoice.validationDate"
                          prepend-inner-icon="event"
                          readonly
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" v-if="supplierInvoice != null && supplierInvoice.status == 'paid'">
                      <v-text-field
                          slot="activator"
                          label="Date de paiement"
                          v-model="supplierInvoice.paymentDate"
                          prepend-inner-icon="event"
                          readonly
                          disabled
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="3" class="text-right">
                      <v-checkbox
                          v-model="supplierInvoice.discord"
                          label="Désaccord"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>

                  <v-expansion-panels v-model="panelcomment" dense>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                      >Commentaires
                      </v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <v-card>
                          <v-card-text>
                            <v-textarea
                                v-model="supplierInvoice.comments"
                            ></v-textarea>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                      >Fichiers
                        <span v-if="supplierInvoice.files.length > 0"
                        >({{ supplierInvoice.files.length }})</span
                        ></v-expansion-panel-header
                      >
                      <v-expansion-panel-content
                          :disabled="supplierInvoice.id === -1"
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <file-items :items="supplierInvoice.files">
                              <template v-slot:default="file">
                                <file-item
                                    :download-url="`/api/file/download/${file.item.path}/${file.item.name}`"
                                    :remove-url="`/api/file/delete/supplierinvoice/${file.item.id}`"
                                    @deleted="filesChanged"
                                    ext="pdf"
                                >{{ file.item.name }}
                                </file-item>
                              </template>
                            </file-items>
                          </v-col>
                          <v-col class="text-right">
                            <v-btn
                                color="blue-grey"
                                class="white--text"
                                @click.native="dialogs.documents = true"
                            >
                              Upload
                              <v-icon right dark>cloud_upload</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <div class="mt-3">
                    <v-btn
                        :disabled="!editable || isNew"
                        small
                        @click="openPrestationDialog"
                    >
                      <v-icon>link</v-icon>
                      Attacher des prestations
                    </v-btn>
                  </div>

                  <v-data-table
                      :items="supplierInvoice.prestations"
                      :headers="headers"
                      no-data-text="-"
                      :footer-props="{
                      'items-per-page-options': listing.rowsPerPageItems,
                      'items-per-page-text': 'Lignes par page',
                    }"
                      class="mt-3 elevation-1"
                  >
                    <template #item.bookingId="{ item, value }">
                      <div>{{ item.bookingId }}</div>
                      <div class="caption">#{{ item.prestationId }}</div>
                    </template>
                    <template #item.status="{ item, value }">
                      <availability
                          v-model="item.status"
                          kind="BookingStatus"
                      />
                      <availability
                          v-model="item.supplierStatus"
                          kind="SupplierStatus"
                      />
                    </template>
                    <template #item.prevamount="{ item }">
                      {{ item.prevAmount | currency(item.prevCurrencyCode) }}
                      <span v-if="item.prevCurrencyCode !== 'EUR'"
                      >({{ item.prevEuroAmount | currency("EUR") }}@{{
                          item.prevEuroRate | currency(item.prevCurrencyCode)
                        }})</span
                      >
                    </template>
                    <template #item.amount="{ item, value }">
                      <div v-if="supplierInvoice != null && supplierInvoice.status === 'paid'">
                        {{
                          item.amount | currency(supplierInvoice.currencyCode)
                        }}
                        <span v-if="supplierInvoice.currencyCode !== 'EUR'"
                        >({{ item.euroAmount | currency("EUR") }})</span
                        >
                      </div>
                      <div v-else>
                        <v-text-field
                            v-model.number="item.amount"
                            reverse
                            :prefix="$t(supplierInvoice.currencyCode + '-symbol')"
                            hide-details
                            single-line
                            :disabled="!editable"
                            class="mt-0 pt-0"
                        ></v-text-field>
                      </div>
                    </template>
                    <template #item.provisionAmount="{ item, value }">
                      <v-text-field
                          v-model.number="item.provisionAmount"
                          reverse
                          :prefix="$t(supplierInvoice.currencyCode + '-symbol')"
                          hide-details
                          single-line
                          class="mt-0 pt-0"
                      ></v-text-field>
                    </template>
                    <template #item.remove="{ item }">
                      <v-btn
                          icon
                          small
                          :disabled="!editable"
                          class="deletePrestation"
                          @click="deletePrestation(item.id)"
                      >
                        <v-icon small>close</v-icon>
                      </v-btn>
                    </template>

                    <template #body.append="{ items, headers }">
                      <td class="text-right pa-4" :colspan="headers.length - 2">
                        <span
                            v-if="diffAmount"
                            class="font-weight-bold mr-5 red--text"
                        >
                          Différence:
                          <span>{{
                              diffAmount | currency(supplierInvoice.currencyCode)
                            }}</span>
                        </span>
                        <span class="mr-2">Total prestations:</span>
                        {{
                          prestationsAmount
                              | currency(supplierInvoice.currencyCode)
                        }}
                      </td>
                      <td></td>
                    </template>
                  </v-data-table>
                  <div class="d-flex justify-space-between align-center mt-3">
                    <v-btn class="mr-4" outlined :disabled="displayBtn" @click="showDelete = true"
                    >Supprimer
                    </v-btn
                    >

                    <span class="white--text pa-2 font-weight-medium red "
                          style="border:1px solid red; border-radius:8px" v-if="insufficientAmount"><v-icon
                        color="white" class="mr-2">mdi-alert</v-icon>Fonds insuffisants sur ce portefeuille</span>
                    <div>
                      <v-btn
                          outlined
                          :loading="saving && savingType === 'save'"
                          @click="save"
                          class="ma-1"
                      >Sauvegarder
                      </v-btn
                      >
                      <v-btn
                          class="primar ma-1y"
                          :disabled="!canValidate || insufficientAmount"
                          :loading="saving && savingType === 'validate'"
                          @click="validate"
                      >Sauvegarder & Valider
                      </v-btn
                      >
                      <v-btn
                          class="primary ma-1"
                          :disabled="!canPay"
                          :loading="saving && savingType === 'pay'"
                          @click="pay"
                      >Payer
                      </v-btn
                      >
                      <v-btn
                          :disabled="
                          supplierInvoice == null ||
                        supplierInvoice.invoiceType == 'credit_note' ||
                        supplierInvoice.status !== 'paid'
                      "
                          outlined
                          @click="createCredit"
                          class="ma-1"
                      >Créer un avoir
                      </v-btn
                      >
                    </div>
                  </div>


                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <smart-dialog
          title="Ajout document"
          v-model="dialogs.documents"
          :width="800"
          v-if="supplierInvoice && supplierInvoice.id"
      >
        <document-uploader
            :url="`/api/file/upload/supplierinvoice/${supplierInvoice.id}`"
            @uploaded="filesChanged"
        />
      </smart-dialog>

      <smart-dialog
          v-model="addPrestationDialog"
          title="Prestations"
          width="1200"
          scrollable
      >
        <v-card-text>
          <v-form @keydown.enter.native="searchPrestation">
            <v-row dense>
              <v-col cols="6">
                <v-text-field
                    type="number"
                    clearable
                    v-model.number="prestationSearchCtx.bookingId"
                    label="Réf dossier"
                    @keypress.enter="searchPrestation"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                    type="number"
                    clearable
                    v-model.number="prestationSearchCtx.voucherId"
                    label="Réf voucher"
                    @keypress.enter="searchPrestation"
                />
              </v-col>
              <v-col cols="6">
                <smart-picker
                    v-model="prestationSearchCtx.prestationStart"
                    label="Date de début"
                    @keypress.enter="searchPrestation"
                />
              </v-col>
              <v-col cols="6">
                <smart-picker
                    v-model="prestationSearchCtx.prestationStop"
                    label="Date de fin"
                    @keypress.enter="searchPrestation"
                ></smart-picker>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="searchPrestation" :loading="loadingPrestations">
                  <v-icon>search</v-icon>
                  Rechercher
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-text>
          <v-data-table
              v-model="selected"
              class="mt-3 elevation-1 tbl"
              :items="prestations"
              :headers="prestationHeaders"
              :loading="loadingPrestations"
              :footer-props="{
              'items-per-page-options': listing.rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
              no-data-text="-"
              show-select
          >
            <!--
            <template slot="headers" slot-scope="props">
              <tr>
                <th style="width: 20px">
                  <v-checkbox
                      :input-value="props.all"
                      :indeterminate="props.indeterminate"
                      primary
                      hide-details
                      @click.stop="toggleAll"
                  ></v-checkbox>
                </th>

                <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                    @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            -->
            <template #item.status="{ item, value }">
              <availability v-model="item.status" kind="BookingStatus"/>
              <availability
                  v-model="item.supplierStatus"
                  kind="SupplierStatus"
              />
            </template>
            <template #item.attachedInvoices="{ value, item }">
              <span
                  v-for="(id, idx) in item.attachedInvoices"
                  :key="idx"
                  class="mx-3"
              >{{ id ? id : "" }}</span
              >
            </template>
            <template #item.supplierAccountingName="{ value, item }">
              <span v-if="item.name">{{ item.name }}</span>
              <div
                  v-if="item.prestationType === 'room' && item.room && item.meal"
              >
                {{ item.room.name }} / {{ item.meal.name }}
              </div>
            </template>
            <template #item.purchase="{ value, item }">{{
                value | currency(item.purchaseCurrency)
              }}
            </template>
            <template #item.purchase="{ value, item }">
              <span v-if="item && item.name">{{ item.name }}</span>
              <div
                  v-if="item.prestationType === 'room' && item.room && item.meal"
              >
                {{ item.room.name }} / {{ item.meal.name }}
              </div>
            </template>
            <template #item.startDate="{ value, item }">
              <div>
                du
                {{
                  _parseAndFormatDate(
                      item.startDate,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.serverLocalDate
                  )
                }}
              </div>
              <div>
                au
                {{
                  _parseAndFormatDate(
                      item.stopDate,
                      datePatternConfig.serverLocalDateTime,
                      datePatternConfig.serverLocalDate
                  )
                }}
              </div>
            </template>
            <template #item.amount="{ item }">{{
                item.purchase | currency(item.purchaseCurrency)
              }}
            </template>
            <template #item.provisionAmount="{ item, value }">
              {{ item.provisionAmount | currency(item.purchaseCurrency) }}
            </template>
          </v-data-table>
        </v-card-text>

        <template #actions>
          <v-spacer/>
          <v-btn @click="addPrestation" :disabled="selected.length == 0">
            <v-icon>link</v-icon>
            Attacher les prestations
          </v-btn>
        </template>
      </smart-dialog>

      <smart-dialog
          title="Suppression facture fournisseur"
          v-model="showDelete"
      >
        <v-card-text>
          <p v-if="supplierInvoice != null && supplierInvoice.status == 'waiting'">
            Si vous supprimez la facture fournisseur les prestations et fichiers
            rattachés seront également supprimés.
          </p>
          <p v-if="supplierInvoice != null && supplierInvoice.status == 'waiting'">Confirmez-vous la suppression ?</p>
          <p v-else class="mb-0">Vous ne pouvez pas supprimer cette facture car son statut n'est pas en attente</p>
        </v-card-text>
        <v-card-actions class="actions-btn">
          <v-spacer/>
          <smart-btn @click.native="deleteInvoice()"
                     :disabled="supplierInvoice != null && supplierInvoice.status != 'waiting'">
            <v-icon small class="mr-1">mdi-delete</v-icon>
            Supprimer
          </smart-btn>
          <smart-btn @click.native="showDelete = false" secondary>
            <v-icon small class="mr-1">mdi-close</v-icon>
            Annuler
          </smart-btn>
        </v-card-actions>
      </smart-dialog>
      <multiple-mail-composer
          from="compta@hotelissima.com"
          :show="mailDialog"
          :contents="mailComposerData"
          @close="()=> mailDialog = false"/>
    </template>
  </layout>
</template>

<script>
import {get, post} from "@/utils/api";

import Layout from "@/views/Layout";
import AccountingNav from "@/views/accounting/AccountingNav";
import FormMixin from "@/components/mixins/FormMixin";

import _ from "lodash";
import {EventBus} from "@/components/commons/event-bus";
import Availability from "../../../components/commons/Availability";
import DocumentUploader from "../../../components/commons/DocumentUploader";
import FileItem from "../../../components/commons/FileItem";
import FileItems from "../../../components/commons/FileItems";
import {parseISO} from "date-fns";
import MultipleMailComposer from "@/components/commons/MultipleMailComposer.vue";

export default {
  name: "SupplierInvoice",
  props: {
    id: {required: true},
  },
  components: {
    MultipleMailComposer,
    FileItems,
    FileItem,
    DocumentUploader,
    Availability,
    Layout,
    AccountingNav,
  },
  mixins: [FormMixin],
  data: function () {
    return {
      showDelete: false,
      valid: true,
      selected: [],
      listing: {
        rowsPerPageItems: [20, 50, 100, 200, 500, {text: "Tous", value: -1}],
      },
      ref: 123,
      headers: [
        {text: "#Dossier - Presta", value: "bookingId", sortable: true},

        {text: "Prestation", value: "prestationName", sortable: true},
        {text: "Date création", value: "creationDate", sortable: true},
        {text: "Status", value: "status"},
        {
          text: "Montant Prév",
          value: "prevamount",
          width: "150px",
          sortable: true,
        },
        {
          text: "Montant Réel",
          value: "amount",
          width: "150px",
          sortable: true,
        },
        {
          text: "Provisions",
          value: "provisionAmount",
          sortable: false,
          width: "100px",
        },
        {
          text: "",
          width: "100px",
          value: "remove",
          sortable: true,
        },
      ],
      prestationHeaders: [
        {
          text: "#Dossier",
          value: "bookingId",
          width: "100px",
          sortable: true,
        },
        {text: "#Presta", value: "id", width: "100px", sortable: true},
        {text: "Prestation", value: "supplierAccountingName", sortable: true},
        {text: "Status", value: "status"},
        {
          text: "Factures rattachées",
          value: "attachedInvoices",
          sortable: true,
        },
        {
          text: "Dates",
          value: "startDate",
          width: "150px",
          sortable: true,
          sort: (a, b) => {
            return (
                this._parseDate(a, this.datePatternConfig.serverLocalDateTime) -
                this._parseDate(b, this.datePatternConfig.serverLocalDateTime)
            );
          },
        },
        {
          text: "Montant",
          value: "amount",
          width: "100px",
          align: "right",
          sortable: true,
        },
        {
          text: "Provisions",
          value: "provisionAmount",
          sortable: false,
          align: "right",
        },
      ],
      prestationSearchCtx: {
        bookingId: null,
        voucherId: null,
        prestationStart: null,
        prestationStop: null,
      },
      prestations: [],
      listSuppliers: [],
      listStatus: [],
      listCurrency: [],
      bankAccounts: [],
      listWallet: [{id: null, name: "Aucun"}],
      loading: false,
      saving: false,
      supplierInvoice: null,
      loadingPrestations: false,
      addPrestationDialog: false,

      isNew: false,

      dialogs: {
        documents: false,
      },
      panelcomment: null,
      mailComposerData: {},
      mailDialog: false,
      mailsByReceiver: {},
    };
  },
  watch: {
    "supplierInvoice.amount": {
      handler(newVal) {
        this.updateEuroAmount();
      },
    },
    "supplierInvoice.euroRate": {
      handler(newVal) {
        this.updateEuroAmount();
      },
    },
    "supplierInvoice.currencyCode": {
      handler(newVal) {
        if (this.supplierInvoice.walletId != null) {
          const wallet = this.listWallet.find(w => w.id == this.supplierInvoice.walletId)
          if (newVal != wallet.currencyCode && this.supplierInvoice.status != "paid") {
            this.supplierInvoice.walletId = null;
          }
        }
      },
    }
  },
  computed: {
    insufficientAmount() {
      return this.wallet4currency.find(w => w.id == this.supplierInvoice.walletId).amount < this.supplierInvoice.amount
    },
    showCustomEuroRate() {
      return this.supplierInvoice.walletId === null;
    },
    prestationsAmount() {
      const total = _.sumBy(this.supplierInvoice.prestations, (p) => {
        return p.amount;
      });

      return total.toFixed(2);
    },
    diffAmount() {
      return this.supplierInvoice.amount - this.prestationsAmount;
    },
    wallet4currency() {
      return this.listWallet.filter((w) => {
        return (w.id == null || w.currencyCode === this.supplierInvoice.currencyCode);
      });
    },
    editable() {
      return this.supplierInvoice != null && this.supplierInvoice.status === "waiting";
    },
    disableChangeRate() {
      return (
          this.supplierInvoice != null &&
          this.supplierInvoice.status !== "paid" &&
          this.supplierInvoice.currencyCode !== "EUR"
      );
    },
    canValidate() {
      return (
          this.supplierInvoice != null &&
          this.supplierInvoice.id !== -1 &&
          this.supplierInvoice.status === "waiting" &&
          this.diffAmount === 0
      );
    },
    canPay() {
      return (
          this.supplierInvoice != null &&
          this.supplierInvoice.id !== -1 &&
          this.supplierInvoice.status === "validated" &&
          this.diffAmount === 0
      );
    },
    euroAmount() {
      if (this.supplierInvoice.currencyCode !== "EUR") {
        return (
            "= " + this._formatCurrency(this.supplierInvoice.euroAmount, "EUR")
        );
      }
      return "";
    },
    displayBtn() {
      if (this.supplierInvoice) {
        return this.supplierInvoice.status !== "waiting";
      }
      return false;
    },
  },
  created() {
    this.prestationSearchCtx.prestationStart = this._formatDate(
        this._addDays(new Date(), -365),
        "dd/MM/yyyy"
    );

    get("/api/accounting/bank-accounts").json(
        (data) => (this.bankAccounts = data)
    );

    get("/api/accounting/list-supplier").json(
        (data) => (this.listSuppliers = data)
    );

    get("/api/accounting/list-status").json((data) => (this.listStatus = data));

    get("/api/accounting/list-currency").json(
        (data) => (this.listCurrency = data)
    );

    get("/api/accounting/wallets").json(
        (data) => {
          const wallets = data.filter(d => d.active && parseISO(d.creationDate) < new Date()).map((w) => {
            w.name =
                " @" +
                w.euroRate + " (" +
                w.amount +
                " " +
                w.currencyCode +
                ") - " +
                w.name;
            return w;
          })
          this.listWallet = this.listWallet.concat(wallets)
        }
    );

    this.isNew = this.id === "create";
    const url = this.isNew
        ? "/api/accounting/supplier-invoice/empty"
        : "/api/accounting/supplier-invoice/" + this.id;

    get(url)
        .json((data) => (this.supplierInvoice = data))
        .then(() => {
          if (this.isNew && this.$route.query.supplierAccountingId) {
            this.supplierInvoice.supplierAccountingId = parseInt(
                this.$route.query.supplierAccountingId
            );
          }
          if (this.$route.query.prestationId) {
            this.addPrestationById(this.$route.query.prestationId);
          }
        });
  },
  methods: {
    getSupplierAccounting(id) {
      return get(`/api/supplier-accounting/${id}`).json((rs) => {
        return rs
      })
    },
    setDefaultBankAccount() {
      const selecteSupplier = this.listSuppliers.find(supplier => supplier.id == this.supplierInvoice.supplierAccountingId)
      const account = this.bankAccounts.filter(ba => ba == selecteSupplier.bankAccount)
      this.supplierInvoice.bankAccount = account.length > 0 ? account[0] : "EUR"
    },
    updateEuroAmount() {
      this.supplierInvoice.euroAmount =
          this.supplierInvoice.amount / this.supplierInvoice.euroRate;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.prestations.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    changeWallet(wallet) {
      if (wallet.id !== null) {
        this.euroRate = wallet.euroRate;
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        const isNew = this.id === "create";
        const url = isNew
            ? "/api/accounting/supplier-invoice/create"
            : "/api/accounting/supplier-invoice/" + this.id;

        const prestations = this.supplierInvoice.prestations;
        const newWithPresta = isNew && prestations.length > 0;

        this.saving = true;
        this.savingType = "save";

        if (isNew) {
          const invoice = {...this.supplierInvoice, ...{prestations: []}};

          post(url, invoice)
              .json((data) => {
                this.supplierInvoice = data;

                if (newWithPresta) {
                  this.supplierInvoice.prestations = prestations.map((p) => {
                    p.supplierInvoiceId = this.supplierInvoice.id;
                    return p;
                  });

                  post(
                      "/api/accounting/supplier-invoice/" + this.supplierInvoice.id,
                      this.supplierInvoice
                  )
                      .json((data) => {
                        this.$router.push({
                          name: "supplier-invoice",
                          params: {id: this.supplierInvoice.id},
                        });
                      })
                      .catch((e) => {
                        EventBus.$emit("toast", {
                          color: "red",
                          text: "Une erreur est survenue",
                          exception: e,
                        });
                      })
                      .then(() => {
                        this.saving = false;
                      });
                } else {
                  this.$router.replace({
                    name: "supplier-invoice",
                    params: {id: this.supplierInvoice.id},
                  });
                }
              })
              .catch((e) => {
                EventBus.$emit("toast", {
                  color: "red",
                  text: "Une erreur est survenue",
                  exception: e,
                });
              });
        } else {
          post(url, this.supplierInvoice)
              .json((data) => {
                this.supplierInvoice = data;
                EventBus.$emit("toast", {
                  color: "green",
                  text: "Les informations ont bien été sauvegardées",
                });
              })
              .catch((e) => {
                EventBus.$emit("toast", {
                  color: "red",
                  text: "Une erreur est survenue",
                  exception: e,
                });
              })
              .then(() => {
                this.saving = false;
              });
        }
      }
    },
    validate() {
      this.saving = true;
      this.savingType = "validate";
      post(
          "/api/accounting/supplier-invoice/" + this.id + "/validate",
          this.supplierInvoice
      )
          .json((data) => {
            //this.$router.push({name: "supplier-invoice", params: {id: data.id}})
            this.supplierInvoice = data;
            EventBus.$emit("toast", {
              color: "green",
              text: "Les informations ont bien été sauvegardées",
            });
          })
          .catch((e) => {
            EventBus.$emit("toast", {
              color: "red",
              text: "Une erreur est survenue",
              exception: e,
            });
          })
          .then(() => {
            this.saving = false;
          });
    },
    pay() {
      this.saving = true;
      this.savingType = "pay";
      post("/api/accounting/supplier-invoice/" + this.id + "/pay", this.supplierInvoice)
          .json((data) => {
            //this.$router.push({name: "supplier-invoice", params: {id: data.id}})
            this.supplierInvoice = data;
            EventBus.$emit("toast", {
              color: "green",
              text: "Les informations ont bien été sauvegardées",
            });
          })
          .catch((e) => {
            EventBus.$emit("toast", {
              color: "red",
              text: "Une erreur est survenue",
              exception: e,
            });
          })
          .then(() => {
            this.saving = false;
            this.prepareMail()
          });
    },
    getMessages(suppliers, lang) {
      const amount = suppliers.reduce((sum, invoice) => sum + invoice.amount, 0);

      const currency = suppliers[0].currencyCode
      const refs = suppliers.map(item => {

        let ids = []
        item.prestations.map(p => {
          if (ids.indexOf(p.bookingId) == -1) {
            ids.push(p.bookingId)
          }
        })
        return `<tr>
                    <td>${item.ref}</td>
                    <td>${item.amount}</td>
                    <td>${currency}</td>
                    <td>${ids.join(", ")}</td>
                </tr>`
      })
      let msg = {
        content: "",
        subject: `Hotelissima: ${lang == "FR" ? 'Règlement de facture' : 'Invoices payment'}`
      }

      const table = `
        <table style="border-collapse: collapse; width: 100%;" border="1">
            <tr>
              <th>Ref</th>
              <th>Amount</th>
              <th>Bank</th>
              <th>Booking</th>
            </tr>
          <tbody>
            ${refs.join("")}
            <tr>
                <td>Total</td>
                <td>${amount}</td>
                <td>${currency}</td>
                <td></td>
            </tr>
          </tbody>
        </table>
        `

      if (lang == "FR") {

        msg.content = `Bonjour,<br/><br/>
                    Un paiement de ${amount} ${currency} vous à été envoyé pour les factures suivantes:<br/>
                    <br/>
                    ${table}
                    <br/>
                    <br/>
                    Cordialement,
                    <br/><br/>
                    ${currency == "USD" ? '<em>Un délais de 7 jours est nécessaire pour que le virement soit visible sur votre compte.</em>' : ''}
`

      } else {
        msg.content = `Hello,<br/>
                  A payment of ${amount} ${currency} has been sent to you for the following invoices:<br/>
                  <br/>
                  ${table}
                  <br/>
                  <br/>
                  Sincerely,
                  <br/>
                  <br/>
                  ${currency == "USD" ? '<em>A period of 7 days is required for the transfer to be visible on your account.</em>' : ''}`
      }

      return msg
    },
    prepareMail() {
      this.getSupplierAccounting(this.supplierInvoice.supplierAccountingId)
          .then((supplier) => {
            const message = this.getMessages([this.supplierInvoice], supplier.lang);
            this.mailsByReceiver[supplier.name] = [this.supplierInvoice]
            this.mailComposerData[supplier.name] = {
              suppliers: [supplier],
              message: message.content,
              subject: message.subject,
              send: true
            }
            this.$nextTick(() => {
              this.mailDialog = true
            })
          })
    },
    openPrestationDialog() {
      this.addPrestationDialog = true;
      this.searchPrestation();
    },
    searchPrestation() {
      this.loadingPrestations = true;
      this.prestations = [];
      this.selected = [];

      this.prestationSearchCtx = {
        ...this.prestationSearchCtx,
        ...{
          bookingId:
              this.prestationSearchCtx.bookingId === ""
                  ? null
                  : this.prestationSearchCtx.bookingId,
          voucherId:
              this.prestationSearchCtx.voucherId === ""
                  ? null
                  : this.prestationSearchCtx.voucherId,
        },
      };

      post(
          "/api/accounting/supplier-invoice/" +
          this.supplierInvoice.id +
          "/search-prestation",
          this.prestationSearchCtx
      )
          .json((data) => {
            this.prestations = data.filter(
                (p) =>
                    this.supplierInvoice.prestations.find(
                        (sp) => sp.prestationId === p.id
                    ) === void 0
            );
          })
          .catch((e) => {
            EventBus.$emit("toast", {
              color: "red",
              text: "Une erreur est survenue",
              exception: e,
            });
          })
          .then(() => {
            this.loadingPrestations = false;
          });
    },
    addPrestation() {
      const newPrestations = this.selected.map((p) => {
        return {
          id: -1,
          bookingId: p.bookingId,
          supplierInvoiceId: this.supplierInvoice.id,
          prestationId: p.id,
          prestationName: p.name,
          creationDate: this._nowFormatted("dd/MM/yyyy HH:mm:ss"),
          amount: p.purchase,
          prevAmount: p.purchase,
          prevCurrencyCode: p.purchaseCurrency,
          currencyCode: p.purchaseCurrency,
          euroAmount: p.euroPurchase,
          euroRate: p.euroPurchaseRate,
          status: p.status,
          supplierStatus: p.supplierStatus,
          prestationEuroAmount: p.euroPurchase,
          provisionAmount: p.provisionAmount,
        };
      });

      this.supplierInvoice.prestations =
          this.supplierInvoice.prestations.concat(newPrestations);

      this.addPrestationDialog = false;
    },
    deletePrestation(itemId) {
      this.supplierInvoice.prestations =
          this.supplierInvoice.prestations.filter((p) => p.id !== itemId);
    },
    addPrestationById(id) {
      const url = `/api/accounting/supplier-invoice/get-prestation/${id}`;
      get(url).json((newPrestations) => {
        this.selected = [].concat(newPrestations);
        this.addPrestation();
      });
    },
    filesChanged() {
      this.dialogs.documents = false;
      //supplierInvoice.files
      const url = `/api/accounting/supplier-invoice/${this.id}/files`;
      get(url).json((files) => {
        if (this.supplierInvoice) {
          this.supplierInvoice.files = files;
        }
      });
    },
    createCredit() {
      const url = `/api/accounting/supplier-invoice/${this.id}/create-credit`;

      get(url).json((response) => {
        if (response.invoiceType == "credit_note") {
          EventBus.$emit("toaster-msg", "Avoir enregistré");
          this.$router.push({
            name: "supplier-invoice",
            params: {id: response.id},
          });
        } else {
          EventBus.$emit("toaster-error", "L'avoir n'a pas pu être créé");
        }
      });
    },
    openInvoice(id) {
      let route = this.$router.resolve({
        name: "supplier-invoice",
        params: {id: id},
      });
      window.open(route.href, "_blank");
    },
    deleteInvoice() {
      const id = this.supplierInvoice.id;
      const url = `/api/accounting/supplier-invoice/${id}/delete`;

      get(url)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(", ")
            );
          })
          .res(() => {
            this.showDelete = false;
            EventBus.$emit("toaster-msg", "Facture fournisseur supprimée");
            this.$router.push({name: "supplier-invoice-list"});
          });
    },
  },
};
</script>

<style scoped>
.deletePrestation {
  display: none;
}

tr:hover .deletePrestation {
  display: initial;
}
</style>
